export const API_END_POINT =
  "https://project-netflix-clone.onrender.com/api/v1/user";

export const options = {
  method: "GET",
  headers: {
    accept: "application/json",
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIzYTUwN2FmYzgwMTNiMTI1YjY4YjZiN2NiMDgzODdjZCIsInN1YiI6IjY2NDFiODQ4YTk4N2FjNTEyOTFkZDlmMSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.-OIA__h_o8il76GEiewnMm5r6zDSeKXRDBlpFXOAbqI",
  },
};

export const Now_Playing_Movie =
  "https://api.themoviedb.org/3/movie/now_playing";

export const Popular_Movie = "https://api.themoviedb.org/3/movie/popular";

export const TopRated_Movie = "https://api.themoviedb.org/3/movie/top_rated";

export const Upcoming_Movie = "https://api.themoviedb.org/3/movie/upcoming";

export const TMDB_IMG_URL = "https://image.tmdb.org/t/p/w500";

export const SEARCH_MOVIE_URL =
  "https://api.themoviedb.org/3/search/movie?query=";
